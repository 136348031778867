"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        adminApiBaseUrl: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/admin/graphql',
        foodOpsApiBaseUrl: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/foodops/graphql',
        siteStaffApiBaseUrl: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/site-staff/graphql',
        patientApiBaseUrl: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/patient/v2/graphql',
        commonApi: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/common',
        apiKey: 'xablauapikeyxablau123-stage',
        webSocketBaseUrl: 'wss://a4w00da8c0.execute-api.us-east-1.amazonaws.com/stage'
    },
    cloudfront: 'd17hqorr29jksg.cloudfront.net',
    cognito: {
        adminUserPoolId: 'us-east-1_eylI8kJtN',
        adminUserPoolClientId: '39fv7v4ova3lcj39gfmj5fp12m',
        foodOpsUserPoolId: 'us-east-1_AOgRqv738',
        foodOpsUserPoolClientId: '3df8maeone4s2je3l5mpj9rr4g',
        patientUserPoolId: 'us-east-1_5sGkTq4wV',
        patientUserPoolClientId: '33jt7drff8n16s6ufc7ef9cb00',
        siteStaffUserPoolId: 'us-east-1_lUA5M6ahH',
        siteStaffUserPoolClientId: '6jk56lluotfd73hsefko4c30vl'
    },
    pinpoint: 'dab104cf8b74420787417f6e71653be7',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
